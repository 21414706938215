@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.propertyPrice {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin-bottom: 1rem;
	margin-left: -0.25rem;
	color: $anthracite-grey;
	line-height: divide(28, 22);
	> * {
		margin-left: 0.25rem;
	}

    &--compact {
        font-size: $font-size-sm;
    }

	// MQs
	@media ($mdDown) {
		font-size: 1.125rem;
		line-height: divide(24, 18);

        &--compact {
            font-size: $font-size-sm;
        }
	}
	@media ($mdUp) {
		margin-left: -0.75rem;
		> * {
			margin-left: 0.75rem;
		}
	}
}

.propertyPriceAmount {
    font-weight: $font-weight-semibold;
    font-size: 1.375rem;

    .propertyPrice--compact & {
        font-size: $font-size-sm;
    }
}

.propertyPriceOriginal {
    position: relative;
    color: $grey-dark;

    // MQs
    @media ($mdDown) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    @media ($xxsDown) {
        overflow-x: clip;
        text-overflow: ellipsis;
    }

    &--crossLine {
        position: absolute;
        top: -30%;
        color: red;
        height: 1px;
        box-shadow: 0 0 2px 0 red;
        width: 120%;
        left: -10%;
        transform: rotateZ(-13deg);

        .propertyPrice--compact & {
            top: -40%;
        }
    }
}

.propertyPriceDiscount {
    color: $red;
    font-weight: $font-weight-semibold;
    margin-top: 0.5rem;

    .propertyPrice--compact & {
        margin-top: 0.25rem;
    }
}

.propertyPriceAdditional {
	color: $grey-dark;
	font-weight: $font-weight-normal;
	font-size: 1rem;
	line-height: divide(22, 16);

    .propertyPrice--compact & {
        font-size: $font-size-sm;
    }
}

.propertyPricePerMeter {
  color: $grey-dark;
}
