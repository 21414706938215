@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.offerCard {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100%;
	border-radius: $border-radius-lg;
	background-clip: padding-box;
	color: $grey-dark;

	// MQs
	@media ($xxxlDown) {
		:global(.propertyPrice) {
			font-size: 1.125rem;
			line-height: divide(24, 18);
		}
	}
	@media ($mdDown) {
		background-color: $white;
	}
	@media ($mdUp) {
		border: 1px solid $grey-medium;
		transition: border-color $transition, background-color $transition;
		// HOVERs
		&:hover {
			border-color: transparent;
			background-color: $white;
		}
	}
}
.offerCardImage {
	position: relative;
	flex-shrink: 0;
	align-self: stretch;
	width: 7.5rem;
	margin-bottom: 0;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
	overflow: hidden;
	&::before {
		content: '';
		display: block;
		padding-top: percentage((divide(1, 1)));
	}

	// MQs
	@media ($fullUp) {
		width: 10.25rem;
	}
}
.offerCardContent {
	flex-grow: 1;
	min-width: 0;
	padding: 1rem;
	a {
		@extend %link-mask;
		color: inherit;
	}
}
.offerCardLabel,
.offerCardAddress {
	&:global(:not(.text-truncate-multiple)) {
		display: block;
	}
}
