@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.offerCardsGrid {
	@include list-unstyled();
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.5rem;
	margin-bottom: -0.75rem;
	margin-left: -0.5rem;

	// MQs
	@media ($lgUp) {
		margin-right: -1rem;
		margin-bottom: -1rem;
		margin-left: -1rem;
	}
}
.offerCardsGridItem {
	flex: 0 0 auto;
	width: 100%;
	margin-bottom: 0.75rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;

	// MQs
	@media ($mdUp) {
		width: percentage(divide(1, 2));
	}
	@media ($xxlUp) {
		width: percentage(divide(1, 3));
	}
	@media ($xxxlUp) {
		margin-bottom: 1rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}
}
