@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.featuresList {
	@include list-unstyled();
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -0.5rem;
	margin-left: -0.75rem;

	// MQs
	@media ($mdUp) {
		margin-left: -1.5rem;
	}
}
.featuresListItem {
	display: inline-flex;
	margin-bottom: 0.5rem;
	margin-left: 0.75rem;

	// MQs
	@media ($mdUp) {
		margin-left: 1.5rem;
	}
}
.featuresListItemIcon {
	&:global(.icon) {
		margin-right: 0.5rem;
	}
}
