@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.stepCard {
	padding: 1.25rem;
	border: 1px solid $grey-medium;
	border-radius: $border-radius-lg;
	background-clip: padding-box;

	// MQs
	@media ($lgUp) {
		padding: 2.5rem;
	}

	&--no-border {
		border: 0;
	}
}
.stepCardHeading {
	margin-bottom: 1rem;

	@media ($xlUp) {
		margin-bottom: 2rem;
	}
}
.stepCardSubHeading {
	padding-top: 1rem;
	border-top: 1px solid $grey-light;

	// MQs
	@media ($xlUp) {
		padding-top: 2rem;
	}
}
.stepCardDescription {
	color: $grey-dark;
}
