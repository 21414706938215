@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.filterList {
	@include list-unstyled();
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -1rem;
	margin-left: -1.5rem;
	color: $grey-dark;
	font-weight: $font-weight-medium;
	font-size: 0.875rem;
	line-height: divide(16, 14);
	text-transform: uppercase;
}
.filterListItem {
	position: relative;
	margin-bottom: 1rem;
	margin-left: 1.5rem;
	padding-left: 1.25rem;
	&::before {
		content: '';
		position: absolute;
		top: 0.25rem;
		left: 0;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: $border-radius-xs;
		background-color: currentColor;
	}
}
